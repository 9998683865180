import React from 'react';
import Select, { components } from 'react-select';
import countryList from 'react-select-country-list';
import styled from 'styled-components';

const FlagOption = (props) => (
  <components.Option {...props}>
    <img
      src={`https://flagcdn.com/w20/${props.data.value.toLowerCase()}.png`}
      alt={props.data.label}
      style={{ marginRight: 10 }}
    />
    {props.data.label}
  </components.Option>
);

const StyledSelect = styled(Select)`
  .react-select__single-value {
    display: flex;
    align-items: center;
  }

  .react-select__option {
    display: flex;
    align-items: center;
  }

  .react-select__option img {
    margin-right: 10px;
  }
`;

const NationalitySelect = ({ value, onChange }) => {
  const options = countryList().getData();

  return (
    <StyledSelect
      options={options}
      components={{ Option: FlagOption }}
      value={options.find(option => option.value === value)} // Buscar por value
      onChange={onChange}
      placeholder="Select nationality"
      isClearable
      isSearchable
    />
  );
};

export default NationalitySelect;  // Exportación única