import React from 'react';
import './CardItem.css';
import imgBotton from '../../assets/img/Boton.png';
import { Link } from "react-router-dom";



const CardItem = ({idSection, imageSrc, textIcon, iconSrc, text1, text2}) => {
    return (
        <div className="card__content" id={idSection}>
            <div className="card__img">
                <img src={imageSrc} alt="Event related" />
            </div>
            <div className="card__text">
                <div className="card__icon">
                    <img src={iconSrc} alt="Icon" />
                    <p>{textIcon}</p>
                </div>
                <p>{text1}</p>
                <p>{text2}</p>
                <Link to="/booking" className="card__button">
                    <img src={imgBotton} alt="" />
                </Link>
            </div>
        </div>
    );
}

export default CardItem;