import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import imgBotton from '../../assets/img/Finish.png';
import paiment from '../../assets/img/paiment.png';
import * as yup from 'yup';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const urlReservation = process.env.REACT_APP_API_URL + '/reservation';

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  phone: yup.string().matches(/^\d{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
  origin: yup.string().required('Origin is required'),
  destination: yup.string().required('Destination is required'),
  departure_date: yup.date().required('Departure date is required'),
  number_days: yup.number().typeError('Number of days must be a number').min(1, 'At least one day is required').required('Number of days is required'),
  type_travel: yup.string().oneOf(['Students', 'Tours', 'Business', 'Student Exchange', 'Others'], 'Invalid travel type').required('Travel type is required'),
  children_count: yup.number().typeError('Number of children must be a number').min(0, 'Number of children cannot be negative').required('Number of children is required'),
  adults_count: yup.number().typeError('Number of adults must be a number').min(1, 'At least one adult is required').required('Number of adults is required'),
  aproximate_budget: yup.number().typeError('Approximate budget must be a number').min(0, 'Budget cannot be negative').required('Approximate budget is required'),
  message: yup.string().optional(),
  state: yup.number().optional()
});

const getFormattedDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const BookingForm = () => {
  const { control, handleSubmit, watch, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      user_id: 1,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      origin: '',
      destination: '',
      departure_date: '',
      return_date: '',
      number_days: '',
      type_travel: '',
      children_count: 0,
      adults_count: 1,
      aproximate_budget: '',
      message: '',
      state: 1,
      created_at: ''
    }
  });

  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data) => {
    const formattedDate = getFormattedDate();
    const processedData = {
      ...data,
      children_count: Number(data.children_count),
      adults_count: Number(data.adults_count),
      number_days: Number(data.number_days),
      aproximate_budget: Number(data.aproximate_budget),
      state: Number(data.state),
      created_at: formattedDate,
      recaptchaToken
    };

    try {
      await axios.post(urlReservation, processedData);
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  return (
    <div className="booking__form_all">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="booking__form_items">
          <div>
            <label>First Name:</label>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => <input {...field} />}
            />
            {errors.first_name && <p className="booking__error">{errors.first_name.message}</p>}
          </div>

          <div>
            <label>Last Name:</label>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => <input {...field} />}
            />
            {errors.last_name && <p className="booking__error">{errors.last_name.message}</p>}
          </div>

          <div>
            <label>E-mail:</label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <input type="email" {...field} />}
            />
            {errors.email && <p className="booking__error">{errors.email.message}</p>}
          </div>

          <div>
            <label>Phone Number:</label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => <input {...field} />}
            />
            {errors.phone && <p className="booking__error">{errors.phone.message}</p>}
          </div>

          <div>
            <label>Travel of Type:</label>
            <Controller
              name="type_travel"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option value="">Select</option>
                  <option value="Students">Students</option>
                  <option value="Tours">Tours</option>
                  <option value="Business">Business</option>
                  <option value="Student Exchange">Student Exchange</option>
                  <option value="Others">Others</option>
                </select>
              )}
            />
            {errors.type_travel && <p className="booking__error">{errors.type_travel.message}</p>}
          </div>

          <div>
            <label>Origin:</label>
            <Controller
              name="origin"
              control={control}
              render={({ field }) => <input {...field} />}
            />
            {errors.origin && <p className="booking__error">{errors.origin.message}</p>}
          </div>

          <div>
            <label>Desired Destination:</label>
            <Controller
              name="destination"
              control={control}
              render={({ field }) => <input {...field} />}
            />
            {errors.destination && <p className="booking__error">{errors.destination.message}</p>}
          </div>

          <div>
            <label>Estimated Departure Date:</label>
            <Controller
              name="departure_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  selected={value}
                  onChange={(date) => onChange(date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  className="your-input-class"
                />
              )}
            />
            {errors.departure_date && <p className="booking__error">{errors.departure_date.message}</p>}
          </div>

          <div>
            <label>Number of Days:</label>
            <Controller
              name="number_days"
              control={control}
              render={({ field }) => <input type="number" {...field} />}
            />
            {errors.number_days && <p className="booking__error">{errors.number_days.message}</p>}
          </div>

          <div>
            <label>Numbers of travels (children):</label>
            <Controller
              name="children_count"
              control={control}
              render={({ field }) => <input type="number" {...field} />}
            />
            {errors.children_count && <p className="booking__error">{errors.children_count.message}</p>}
          </div>

          <div>
            <label>Numbers of travels (Adults):</label>
            <Controller
              name="adults_count"
              control={control}
              render={({ field }) => <input type="number" {...field} />}
            />
            {errors.adults_count && <p className="booking__error">{errors.adults_count.message}</p>}
          </div>

          <div>
            <label>Approximate Budget:</label>
            <Controller
              name="aproximate_budget"
              control={control}
              render={({ field }) => <input type="number" {...field} />}
            />
            {errors.aproximate_budget && <p className="booking__error">{errors.aproximate_budget.message}</p>}
          </div>

          <div className='booking__form_textarea'>
            <label>Comments:</label>
            <Controller
              name="message"
              control={control}
              render={({ field }) => <textarea {...field} />}
            />
            {errors.message && <p className="booking__error">{errors.message.message}</p>}
          </div>

          <ReCAPTCHA
            sitekey="6Lc-_CAqAAAAAF6cune0Ghb_qQqSxcn6kGEcVePb"
            onChange={(token) => setRecaptchaToken(token)}
          />
        </div>
        <div className="send_form">
          <img src={paiment} alt="paiment" />
          <div className='boton_to_send' onClick={handleSubmit(onSubmit)}>
            <img src={imgBotton} alt="Submit" />
          </div>
        </div>
        {isSubmitted && <p className="success-message">Your reservation has been submitted successfully!</p>}
      </form>
    </div>
  );
};

export default BookingForm;
