import React, { Component } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import './PageLogin.css';

const url = process.env.REACT_APP_API_URL +"/login";
const cookies = new Cookies();

class PageLogin extends Component {
  state = {
    form: {
      email: '',
      password: ''
    },
    loginUser: false,
    loading: true,
    redirect: false
  }

  componentDidMount() {
    const jwtToken = cookies.get('access_token');
    if (jwtToken) {
      this.setState({ loginUser: true, redirect: true });
    }
    this.setState({ loading: false });
  }

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  iniciarSesion = async () => {
    const { email, password } = this.state.form;
  
    if (email.length <= 0 || password.length <= 0) {
      alert('Se requieren todos los datos');
      return;
    }
  
    try {
      const response = await axios.post(url, { email, password }, { withCredentials: true });
      console.log(response.data);
  
      if (response.data.accessToken) {
        // Guardar tokens en cookies del lado del cliente
        cookies.set('access_token', response.data.accessToken, { path: '/', maxAge: 3600 });
        cookies.set('refresh_token', response.data.refreshToken, { path: '/', maxAge: 604800 });
  
        // Guardar información del usuario en cookies
        cookies.set('user_data', JSON.stringify(response.data.user), { path: '/', maxAge: 3600 });
  
        // Establecer un retraso de 2 segundos antes de redirigir
        this.setState({ redirect: true });
        
      } else {
        alert("Verificar Usuario y/o Clave");
      }
    } catch (error) {
      console.error("Error de inicio de sesión:", error);
      if (error.response) {
        console.error("Error Response:", error.response.data);
      } else if (error.request) {
        console.error("No Response:", error.request);
      } else {
        console.error("Error:", error.message);
      }
      alert("Ocurrió un error. Intente nuevamente.");
    }
  }
  
  

  render() {
    // Mostrar "Cargando..." mientras se verifica el JWT
    if (this.state.loading) {
      return <h1>Cargando...</h1>;
    }

    // Redirigir si el usuario ya está autenticado
    if (this.state.redirect) {
      return <Navigate to="/dashboard" />;
    }

    return (
      <div className="formulario_login">
        <div className="formulario_items">
          <div className="formulario_header">
            <h2> Log in </h2>
          </div>
          <form className="formulario_form">
            <label htmlFor='email'>E-mail</label>
            <input type="text" name="email" id='email' onChange={this.handleChange}></input>
            <label htmlFor='password'>Password</label>
            <input type="password" name="password" id='password' onChange={this.handleChange}></input>
            <div className='boton-login' onClick={() => { console.log("Login clicked"); this.iniciarSesion(); }}>LOGIN</div>
          </form>
        </div>
      </div>
    );
  }
}

export default PageLogin;
