import React, { Component } from "react";
import CardItem from '../../../components/CardItem/CardItem';
import imgHome from '../../../assets/img/Home.jpg';
import img1 from '../../../assets/img/Schols.jpg';
import img2 from '../../../assets/img/Tours.jpg';
import img3 from '../../../assets/img/Business.jpg';
import img4 from '../../../assets/img/Students.jpg';
import icon1 from '../../../assets/img/Education.png';
import icon2 from '../../../assets/img/Tour.png';
import icon3 from '../../../assets/img/Bussiness.png';
import icon4 from '../../../assets/img/Exchange.png';
import './PageHome.css';
import { Link } from 'react-scroll';

class PageHome extends Component{
    render(){
        return(
            <>
                <section className="link__card_grid">
                    <Link to="students" smooth={true} duration={500}>
                        <div className="link__card">
                            <div className="link__card_img">
                                <img src={icon1} alt="icon" />
                            </div>
                            <div className="link__card_text">
                                Students
                            </div>
                        </div>
                    </Link>
                    <Link to="tours" smooth={true} duration={500}>
                        <div className="link__card">
                            <div className="link__card_img">
                                <img src={icon2} alt="icon" />
                            </div>
                            <div className="link__card_text">
                                Tours
                            </div>
                        </div>
                    </Link>
                    <Link to="business" smooth={true} duration={500}>
                        <div className="link__card">
                            <div className="link__card_img">
                                <img src={icon3} alt="icon" />
                            </div>
                            <div className="link__card_text">
                                Business
                            </div>
                            
                        </div>
                    </Link>
                    <Link to="student_Exchange" smooth={true} duration={500}>
                        <div className="link__card">
                            <div className="link__card_img">
                                <img src={icon4} alt="icon" />
                            </div>
                            <div className="link__card_text">
                                Student Exchange
                            </div>
                            
                        </div>
                    </Link>
                    
                </section>
                <section className="banner">
                    <img src={imgHome} alt="" />
                    <div className="banner_text">
                        <h1 style={{ fontFamily: 'BrandonPrintedTwoShadow' }}>DISCOVER THE WORLD</h1>
                    </div>
                </section>
                <main>
                    <section className="card__grid">
                        <CardItem
                            idSection="students"
                            imageSrc={img1}
                            textIcon="Students"
                            iconSrc={icon1}
                            text1="Welcome to Explorex, your go-to for student travel adventures! We offer tickets for educational trips and fun excursions, from cultural tours to international study journeys. Find the perfect options for any interest and budget, and start creating unforgettable experiences. Your next student adventure begins here!" 
                        />
                        <CardItem
                            idSection="tours"
                            imageSrc={img2}
                            textIcon="Tours"
                            iconSrc={icon2}
                            text1="Welcome to Explorex, your gateway to global adventures! We specialize in offering tickets for unforgettable travel experiences, whether you’re exploring bustling cities, relaxing on serene beaches, or discovering hidden gems around the world. Our easy-to-use platform helps you find the perfect tickets for any destination and budget. Start planning your next adventure and let the world be your playground!" 
                        />
                        <CardItem
                            idSection="business"
                            imageSrc={img3}
                            textIcon="Business"
                            iconSrc={icon3}
                            text1="Welcome to Explorex, your partner for seamless business travel. We provide tickets for corporate trips, whether you’re attending meetings, conferences, or client events. Our user-friendly platform helps you find the best options to fit your schedule and budget. Optimize your travel experience and focus on what matters most—your business. Start planning your next trip with us today!" 
                        />
                        <CardItem
                            idSection="student_Exchange"
                            imageSrc={img4}
                            textIcon="Student Exchange"
                            iconSrc={icon4}
                            text1="We specialize in managing tickets for groups from schools, universities, and businesses. Our goal is to streamline event planning and ensure a hassle-free experience. From ticket reservations to coordinating details, our team is here to make everything easier for you." 
                            text2="Let us handle the logistics so you can focus on enjoying the event! Contact us to find out how we can assist you."
                        />
                    </section>
                </main>
            </>
        )
    }
}

export default PageHome;