import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from './components/Navbar_main/Navbar';
import PageDashboard from './views/cms/PageDashboard/PageDashboard';
import PageHome from './views/pages/PageHome/PageHome';
import PageLogin from './views/pages/PageLogin/PageLogin';
import PageBookingForm from './views/pages/PageBookingForm/PageBookingForm';
import PageBooking from './views/pages/PageBooking/PageBooking';
import PageInfoReservation from './views/cms/PageInfoReservation/PageInfoReservation';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

function App() {
  const user = cookies.get('user_data');

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<PageHome />} />
        <Route path="/booking" element={<PageBooking />} />
        <Route path="/login" element={(user != null) ? <Navigate to="/dashboard" /> : <PageLogin />} />
        <Route path="/dashboard" element={<PageDashboard /> } />
        <Route path="/bookingform/:id" element={<PageBookingForm />} />
        <Route path="/info/reservation/:id" element={(user != null) ? <PageInfoReservation /> : <Navigate to="/login" />} />
      </Routes>
      <footer>
        <p className='footer'>© Explorex 2024 - Copy Right</p>
      </footer>
    </BrowserRouter>
  );
}

export default App;
