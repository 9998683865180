import React, { Component } from 'react';
import logo from '../../assets/img/Logo_Group_explorex.png';
import paiment from '../../assets/img/paiment.png';
import {Link } from "react-router-dom";

class Navbar extends Component {
  //Estados React para los cambios del Menu
  constructor(props) {
    super(props);
    this.state = {
      menu: "desactive_menu_items",
      icon: "desactive_menu_icon",
      iconUser: "desactive_iconUser",
    };
    this.menuActive = this.menuActive.bind(this);
  }

  // Funciones Para el Responsive del Menu
  menuActive() {
    var menuState = (this.state.menu === "desactive_menu_items") ? "active_menu_items" : "desactive_menu_items";
    var iconState = (this.state.icon === "desactive_menu_icon") ? "active_menu_icon" : "desactive_menu_icon";
    this.setState({
       menu: menuState,
       icon: iconState
    });
  }
  render() {
    return (
      <>
        <nav className="menu">
          <div className="menu__all">

            <div className="menu__logo">
              <a href="/">
                <img src={logo} alt="Logo" />
              </a>
            </div>

            <ul className={`menu__content ${this.state.menu}`}>
              <li><a href="mailto:Info@groupexplorex.com"> Contact Us</a></li>
              <li><Link to="/login">Login</Link></li>
            </ul>

            <div className="menu__paiment">
              <img src={paiment} alt="paiment" />
            </div>
          </div>

          <div className={`icon_menu_top ${this.state.icon}`} onClick={this.menuActive}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </nav>
      </>
    )
  }
}

export default Navbar;