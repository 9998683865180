import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faPlane, faMapMarkerAlt, faCalendarAlt, faChild, faUsers, faMoneyBillWave, faCommentDots, faClock } from '@fortawesome/free-solid-svg-icons';
import './PageInfoReservation.css'; // Importando los estilos CSS

const cookies = new Cookies();
const url = process.env.REACT_APP_API_URL + "/reservation";
const urlPassengers = process.env.REACT_APP_API_URL + "/passenger";

const PageInfoReservation = () => {
  const { id } = useParams();
  const [reservationDetails, setReservationDetails] = useState(null);
  const [passengers, setPassengers] = useState([]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Mes en formato UTC
    const day = String(date.getUTCDate()).padStart(2, '0'); // Día en formato UTC
    const year = date.getUTCFullYear(); // Año en formato UTC
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    axios.get(`${url}/${id}`)
      .then((response) => {
        const formattedData = {
          ...response.data,
          departure_date: formatDate(response.data.departure_date),
          return_date: formatDate(response.data.return_date),
          created_at: formatDate(response.data.created_at)
        };
        setReservationDetails(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching reservation details:", error);
      });

    axios.get(`${urlPassengers}/reservation/${id}`)
      .then((response) => {
        const formattedPassengers = response.data.map((passenger) => ({
          ...passenger,
          birth_date: formatDate(passenger.birth_date)
        }));
        setPassengers(formattedPassengers);
      })
      .catch((error) => {
        console.error("Error fetching passengers:", error);
      });
  }, [id]);

  return (
    <div className="container mt-5">
      {reservationDetails ? (
        <div className="reservation-details card p-4 mb-4">
          <h2 className="mb-4">Reservation Details</h2>
          <div className="row">
            <div className="col-md-6">
              <p><FontAwesomeIcon icon={faUser} /> <strong>First Name:</strong> {reservationDetails.first_name}</p>
              <p><FontAwesomeIcon icon={faUser} /> <strong>Last Name:</strong> {reservationDetails.last_name}</p>
              <p><FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> {reservationDetails.email}</p>
              <p><FontAwesomeIcon icon={faPhone} /> <strong>Phone:</strong> {reservationDetails.phone}</p>
              <p><FontAwesomeIcon icon={faPlane} /> <strong>Travel Type:</strong> {reservationDetails.type_travel}</p>
            </div>
            <div className="col-md-6">
              <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Origin:</strong> {reservationDetails.origin}</p>
              <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Destination:</strong> {reservationDetails.destination}</p>
              <p><FontAwesomeIcon icon={faCalendarAlt} /> <strong>Departure Date:</strong> {reservationDetails.departure_date}</p>
              <p><FontAwesomeIcon icon={faCalendarAlt} /> <strong>Return Date:</strong> {reservationDetails.return_date}</p>
              <p><FontAwesomeIcon icon={faClock} /> <strong>Number of Days:</strong> {reservationDetails.number_days}</p>
            </div>
            <div className="col-md-6">
              <p><FontAwesomeIcon icon={faChild} /> <strong>Children Count:</strong> {reservationDetails.children_count}</p>
              <p><FontAwesomeIcon icon={faUsers} /> <strong>Adults Count:</strong> {reservationDetails.adults_count}</p>
              <p><FontAwesomeIcon icon={faMoneyBillWave} /> <strong>Approximate Budget:</strong> {reservationDetails.aproximate_budget}</p>
            </div>
            <div className="col-md-6">
              <p><FontAwesomeIcon icon={faCommentDots} /> <strong>Message:</strong> {reservationDetails.message}</p>
              <p><FontAwesomeIcon icon={faClock} /> <strong>Created At:</strong> {reservationDetails.created_at}</p>
              <p><FontAwesomeIcon icon={faUser} /> <strong>User ID:</strong> {reservationDetails.user_id}</p>
              <p><FontAwesomeIcon icon={faUser} /> <strong>State:</strong> {reservationDetails.state}</p>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading reservation details...</p>
      )}

      {passengers.length > 0 ? (
        <div className="passengers-list card p-4">
          <h2 className="mb-4">Passengers</h2>
          <div className="row">
            {passengers.map((passenger) => (
              <div className="col-md-6" key={passenger.id_item}>
                <div className="passenger-item mb-3">
                  <p><FontAwesomeIcon icon={faUser} /> <strong>First Name:</strong> {passenger.first_name}</p>
                  <p><FontAwesomeIcon icon={faUser} /> <strong>Last Name:</strong> {passenger.last_name}</p>
                  <p><FontAwesomeIcon icon={faCalendarAlt} /> <strong>Birth Date:</strong> {passenger.birth_date}</p>
                  <p><FontAwesomeIcon icon={faUser} /> <strong>Gender:</strong> {passenger.gender}</p>
                  <p><FontAwesomeIcon icon={faUser} /> <strong>Nationality:</strong> {passenger.nationality}</p>
                  <p><FontAwesomeIcon icon={faUser} /> <strong>Type:</strong> {passenger.type}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>No passengers found for this reservation.</p>
      )}
    </div>
  );
};

export default PageInfoReservation;