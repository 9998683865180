import React, { Component } from "react";
import { useParams } from "react-router-dom";
import BookingFormPassenger from "../../../components/BookingForm/BookingFormPassenger";
import axios from "axios";
import './PageBookingForm.css';

import * as Yup from 'yup';

// Higher-order component to inject route parameters as props
function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

const passengerSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  birth_date: Yup.date().required('Birth date is required'),
  gender: Yup.string().oneOf(['male', 'female', 'other']).required('Gender is required'),
  nationality: Yup.string().required('Nationality is required'),
});

class PageBookingForm extends Component {
  state = {
    passengers: [],
    reservationDetails: null,
    errors: {},
    state: null
  };

  componentDidMount() {
    const { id } = this.props.params;

    // API call to get reservation details
    axios
      .get(process.env.REACT_APP_API_URL+`/reservation/${id}`)
      .then((response) => {
        const reservation = response.data;
        this.setState({ reservationDetails: reservation });
        this.setState({ state: reservation.state });

        // Create passenger forms based on the number of adults and children
        const currentDate = new Date().toISOString();

        const passengers = [];
        for (let i = 0; i < reservation.adults_count; i++) {
          passengers.push({
            reservation_id: `${id}`,
            id_item: `adult_${i}`, // Add an id to each passenger
            type: 'adult',
            first_name: '',
            last_name: '',
            birth_date: '',
            gender: '',
            nationality: '',
            created_at: currentDate
          });
        }

        for (let i = 0; i < reservation.children_count; i++) {
          passengers.push({
            reservation_id: `${id}`,
            id_item: `child_${i}`, // Add an id to each passenger
            type: 'child',
            first_name: '',
            last_name: '',
            birth_date: '',
            gender: '',
            nationality: '',
            created_at: currentDate
          });
        }

        this.setState({ passengers });
      })
      .catch((error) => {
        console.error("Error fetching reservation details:", error);
      });
  }

  handlePassengerChange = (id_item, updatedPassenger) => {
    this.setState((prevState) => {
      const updatedPassengers = prevState.passengers.map((passenger) =>
        passenger.id_item === id_item ? updatedPassenger : passenger
      );
      return { passengers: updatedPassengers };
    });
  };

  validatePassengers = async (passengers) => {
    const errors = {};
    await Promise.all(
      passengers.map(async (passenger, index) => {
        try {
          await passengerSchema.validate(passenger, { abortEarly: false });
        } catch (err) {
          errors[index] = err.errors;
        }
      })
    );
    return errors;
  };

  handleSubmit = async (e) => {
    const { id } = this.props.params;
    e.preventDefault();
    const { passengers, reservationDetails } = this.state;
    const errors = await this.validatePassengers(passengers);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    passengers.forEach((passenger) => {
      fetch(process.env.REACT_APP_API_URL+'/passenger', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(passenger),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .then(
        this.setState({ state: 3 })
      )
      .catch((error) => {
        console.error('Error:', error);
      });
    });

    axios.patch(process.env.REACT_APP_API_URL+`/reservation/${id}`, {
      ...reservationDetails,
      state: 3
    });
  };

  render() {
    const { reservationDetails, passengers, state, errors } = this.state;

    if (!reservationDetails || passengers.length === 0 || state === 3) {
      return <p>The form has been submitted successfully</p>;
    }

    return (
      <div>
        <section className="booking__text">
          <h1>Booking Form</h1>
          <p>Booking ID: {reservationDetails.id}</p>
        </section>
        <form onSubmit={this.handleSubmit}>
          {passengers.map((passenger, index) => (
            <div key={passenger.id_item}>
              <BookingFormPassenger
                passenger={passenger}
                onChange={this.handlePassengerChange}
              />
              
              {errors[index] && (
                <div>
                  {errors[index].map((error, i) => (
                    <p key={i} style={{ color: 'red' }}>{error}</p>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className="form__send">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    );
  }
}

export default withParams(PageBookingForm);
