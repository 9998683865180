import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faEye, faLink } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Component } from 'react';
import Cookies from 'universal-cookie';
import './PageDashboard.css'
const cookies = new Cookies();

const url= process.env.REACT_APP_API_URL+"/reservation";

class PageDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalInsertar: false,
      modalEliminar: false,
      tipoModal: '',
      stateItem: 1,
      goHome: false,
      form: {
        id: '',
        user_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        type_travel: '',
        origin: '',
        destination: '',
        departure_date: '',
        return_date: '',
        number_days: '',
        children_count: '',
        adults_count: '',
        aproximate_budget: '',
        message: ''
      }
    };
  }
  cerrarSesion = async () => {
    try {
      // Elimina las cookies del cliente
      cookies.remove('access_token', { path: '/' });
      cookies.remove('refresh_token', { path: '/' });
      cookies.remove('user_data', { path: '/' });
      
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      alert("Ocurrió un error al cerrar sesión. Intente nuevamente.");
    }
  };
  formatDateToDisplay = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  peticionGet = () => {
    axios.get(url).then(response => {
      const data = response.data.map(item => ({
        ...item,
        departure_date: this.formatDateToDisplay(item.departure_date),
        return_date: this.formatDateToDisplay(item.return_date)
      }));
      this.setState({ data });
    }).catch(error => {
      console.log(error.message);
    });
  }

  peticionPost = async () => {
    await axios.post(url, this.state.form).then(response => {
      this.modalInsertar();
      this.peticionGet();
    }).catch(error => {
      console.log(error.message);
    })
  }

  peticionPut = () => {
    const { form } = this.state;
    const processedData = {
      ...form,
      children_count: Number(form.children_count),
      adults_count: Number(form.adults_count),
      number_days: Number(form.number_days),
      aproximate_budget: Number(form.aproximate_budget),
      state: Number(form.state)
    };

    axios.patch(`${url}/${this.state.form.id}`, processedData).then(response => {
      this.modalInsertar();
      this.peticionGet();
    }).catch(error => {
      console.log(error.message);
    })
  }

  peticionDelete = () => {
    axios.delete(`${url}/${this.state.form.id}`).then(response => {
      this.modalEliminar();
      this.peticionGet();
    }).catch(error => {
      console.log(error.message);
    })
  }

  seleccionarUsuario = (reservation) => {
    this.setState({
      tipoModal: 'actualizar',
      form: { ...reservation }
    });
  }

  modalInsertar = () => {
    this.setState({ modalInsertar: !this.state.modalInsertar });
  }

  modalEliminar = () => {
    this.setState({ modalEliminar: !this.state.modalEliminar });
  }

  handleChange = async e => {
    e.persist();
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
    console.log(this.state.form);
  }

  componentDidMount() {
    this.peticionGet();
  }

  render() {  
    const form = this.state.form;
    const user = cookies.get('user_data');
    return (
      <div className="App content_tabla">
        {
          user ?(
            <>
          <div className="cont_menu_list">
            <button className="btn btn-success" onClick={() => {this.setState({stateItem: 1})}}>New Reservations</button>
            <button className="btn btn-success" onClick={() => {this.setState({stateItem: 3})}}>Completed form</button>
            <Link onClick={() => {this.cerrarSesion()}} to={`/`} className="btn btn-primary">
              LogOut
            </Link>
          </div>
          <h2>
            {this.state.stateItem === 1 ? "Reservation New" : ""}
            {this.state.stateItem === 3 ? "Completed form" : ""}
          </h2>
          <table className="table tabla">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map(reservation => {
                if (reservation.state === this.state.stateItem) {
                  return (
                    <tr key={reservation.id}>
                      <td>{reservation.first_name} {reservation.last_name}</td>
                      <td>{reservation.email}</td>
                      <td>{reservation.phone}</td>
                      <td>
                        <Link to={`/bookingform/${reservation.id}`} className="btn btn-primary">
                          <FontAwesomeIcon icon={faLink} />
                        </Link>
                        <Link to={`/info/reservation/${reservation.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                        <button className="btn btn-primary" onClick={() => {this.seleccionarUsuario(reservation); this.modalInsertar()}}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button className="btn btn-danger" onClick={() => {this.seleccionarUsuario(reservation); this.modalEliminar()}}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>

          <Modal isOpen={this.state.modalInsertar}>
            <ModalHeader style={{ display: 'block' }}></ModalHeader>
            <ModalBody>
              <div>
                <label htmlFor="id">ID</label>
                <input className="form-control" type="text" name="id" id="id" readOnly onChange={this.handleChange} value={form ? form.id : this.state.data.length + 1}></input>
                <br />
                <label htmlFor="user_id">User ID</label>
                <input className="form-control" type="text" name="user_id" id="user_id" onChange={this.handleChange} value={form ? form.user_id : ''}></input>
                <br />
                <label htmlFor="first_name">First Name</label>
                <input className="form-control" type="text" name="first_name" id="first_name" onChange={this.handleChange} value={form ? form.first_name : ''}></input>
                <br />
                <label htmlFor="last_name">Last Name</label>
                <input className="form-control" type="text" name="last_name" id="last_name" onChange={this.handleChange} value={form ? form.last_name : ''}></input>
                <br />
                <label htmlFor="email">Email</label>
                <input className="form-control" type="text" name="email" id="email" onChange={this.handleChange} value={form ? form.email : ''}></input>
                <br />
                <label htmlFor="phone">Phone</label>
                <input className="form-control" type="text" name="phone" id="phone" onChange={this.handleChange} value={form ? form.phone : ''}></input>
                <br />
                <label htmlFor="type_travel">Type of Travel</label>
                <input className="form-control" type="text" name="type_travel" id="type_travel" onChange={this.handleChange} value={form ? form.type_travel : ''}></input>
                <br />
                <label htmlFor="origin">Origin</label>
                <input className="form-control" type="text" name="origin" id="origin" onChange={this.handleChange} value={form ? form.origin : ''}></input>
                <br />
                <label htmlFor="destination">Destination</label>
                <input className="form-control" type="text" name="destination" id="destination" onChange={this.handleChange} value={form ? form.destination : ''}></input>
                <br />
                <label htmlFor="departure_date">Departure Date</label>
                <input className="form-control" type="date" name="departure_date" id="departure_date" onChange={this.handleChange} value={form ? form.departure_date : ''}></input>
                <br />
                <label htmlFor="return_date">Return Date</label>
                <input className="form-control" type="date" name="return_date" id="return_date" onChange={this.handleChange} value={form ? form.return_date : ''}></input>
                <br />
                <label htmlFor="number_days">Number of Days</label>
                <input className="form-control" type="number" name="number_days" id="number_days" onChange={this.handleChange} value={form ? form.number_days : ''}></input>
                <br />
                <label htmlFor="children_count">Children Count</label>
                <input className="form-control" type="number" name="children_count" id="children_count" onChange={this.handleChange} value={form ? form.children_count : ''}></input>
                <br />
                <label htmlFor="adults_count">Adults Count</label>
                <input className="form-control" type="number" name="adults_count" id="adults_count" onChange={this.handleChange} value={form ? form.adults_count : ''}></input>
                <br />
                <label htmlFor="aproximate_budget">Approximate Budget</label>
                <input className="form-control" type="text" name="aproximate_budget" id="aproximate_budget" onChange={this.handleChange} value={form ? form.aproximate_budget : ''}></input>
                <br />
                <label htmlFor="message">Message</label>
                <textarea className="form-control" name="message" id="message" onChange={this.handleChange} value={form ? form.message : ''}></textarea>
                <br />
              </div>
            </ModalBody>
            <ModalFooter>
              {
                this.state.tipoModal === 'insertar' ?
                <button className="btn btn-success" onClick={() => this.peticionPost()}>Insert</button>
                :
                <button className="btn btn-success" onClick={() => this.peticionPut()}>Change</button>
              }
              <button className="btn btn-danger" onClick={() => this.modalInsertar()}>Cancel</button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.modalEliminar}>
            <ModalBody>
              Are you sure you want to delete this reservation?
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-danger" onClick={() => this.peticionDelete()}>Sí</button>
              <button className="btn btn-secondary" onClick={() => this.modalEliminar()}>No</button>
            </ModalFooter>
          </Modal>
            </>
      
          ) : (
            <h1>You do not have authorization</h1>
          )
        }
      </div>
    );
  }
}

export default PageDashboard;
