import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NationalitySelect from "../../components/NationalitySelect/NationalitySelect";

const BookingFormPassenger = ({ passenger, onChange }) => {
  const [nationality, setNationality] = useState(passenger.nationality || "");
  const [birthDate, setBirthDate] = useState(passenger.birth_date ? new Date(passenger.birth_date) : null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(passenger.id_item, { ...passenger, [name]: value });

    if (name === "nationality") {
      setNationality(value);
    }
  };

  const handleDateChange = (date) => {
    setBirthDate(date);
    const formattedDate = date ? date.toISOString().split('T')[0] : ''; // Formatea la fecha a YYYY-MM-DD
    onChange(passenger.id_item, { ...passenger, birth_date: formattedDate });
  };

  const handleNationalityChange = (selectedOption) => {
    const selectedNationality = selectedOption ? selectedOption.label : ''; 
    setNationality(selectedNationality);
    onChange(passenger.id_item, { ...passenger, nationality: selectedNationality });
  };

  return (
    <div className="booking__form_all">
      <h3>{passenger.type.charAt(0).toUpperCase() + passenger.type.slice(1)}</h3>
      <div className="booking__form_items">
        <div>
          <label htmlFor={`first_name_${passenger.id_item}`}>First Name:</label>
          <input
            type="text"
            name="first_name"
            value={passenger.first_name}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor={`last_name_${passenger.id_item}`}>Last Name:</label>
          <input
            type="text"
            name="last_name"
            value={passenger.last_name}
            onChange={handleChange}
          />
        </div>

        <div>
          <label htmlFor={`birth_date_${passenger.id_item}`}>Birth Date:</label>
          <DatePicker
            selected={birthDate}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
          />
        </div>

        <div>
          <label htmlFor={`gender_${passenger.id_item}`}>Gender:</label>
          <select
            name="gender"
            value={passenger.gender}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="form__nationality">
          <label htmlFor={`nationality_${passenger.id_item}`}>Nationality:</label>
          <NationalitySelect
            value={nationality}
            onChange={handleNationalityChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingFormPassenger;
