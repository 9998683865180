import React, { Component } from "react";
import BookingForm from '../../../components/BookingForm/BookingForm';
import './PageBooking.css';

class PageHome extends Component{
    render(){
        return(
            <>
                <main>
                    <section className="booking__text">
                        <p>We're passionate about educational travel and dedicated to crafting unforgettable experiences for your school trips. To get a personalized, no-obligation quote, simply provide us with a few details about your trip. One of our expert travel consultants will promptly reach out with a tailored estimate.
                        </p>
                        <p>We specialize in custom group tours designed to meet your specific needs and budget. Thank you for considering us let's work together to create an amazing adventure for your group!
                        </p>
                    </section>
                    <BookingForm />
                </main>
            </>
        )
    }
}

export default PageHome;